import React from 'react';
import {
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';

import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: '88vh',
  },
  affiliationInfo: {
    marginTop: 25,
  },
  text404: {
    fontSize: '5rem',
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Grid container direction="column" wrap="nowrap" justify="space-around" alignContent="center" alignItems="center" spacing={8} className={classes.wrapper}>
        <Grid item key="1" md={12} className={classes.affiliationInfo}>
          <Typography variant="h1" component="h1" align="center" gutterBottom className={classes.text404}>
            4o4
          </Typography>
          <Typography variant="h2" component="h2" align="center" gutterBottom>
            Page not found :(
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  );
}
